import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import linky_logo from "../../assets/img/linky_logo2.png";
// reactstrap components
import {
    Collapse,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
    // UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col
    // UncontrolledTooltip,
} from "reactstrap";

import { useMediaQuery } from 'react-responsive'

import SearchBarComp from "components/SearchBarComponent/SearchBarComp";

import "../../assets/css/style.scss";
import GoogleTranslate from "./GoogleTranslate";

function ExamplesNavbar({ articlePage }) {
    // const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    // React.useEffect(() => {
    //     const updateNavbarColor = () => {
    //         if (
    //             document.documentElement.scrollTop > 399 ||
    //             document.body.scrollTop > 399
    //         ) {
    //             setNavbarColor("");
    //         } else if (
    //             document.documentElement.scrollTop < 400 ||
    //             document.body.scrollTop < 400
    //         ) {
    //             setNavbarColor("navbar-transparent");
    //         }
    //     };
    //     window.addEventListener("scroll", updateNavbarColor);
    //     return function cleanup() {
    //         window.removeEventListener("scroll", updateNavbarColor);
    //     };
    // });

    const articleNav = {
        background: "none",
        position: "fixed",
        width: "100%",
        zIndex: "1000",
    };

    const normalNav = {
        background: "transparent",
        position: "absolute",
        width: "100%",
        zIndex: "1000",
    };

    const isMobile = useMediaQuery({ query: '(max-width: 480px)' })

    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar
                // className={"fixed-top " + navbarColor}
                className="mb-0 no-bs fixed-top "
                style={articlePage ? articleNav : normalNav}
                // style={{
                //     // background: "transparent",
                //     // position: "absolute",
                //     width: "100%",
                //     zIndex: "1000",
                //     // background: "#4E5160"
                // }}
                // color="info"
                expand="lg"
            >
                <div className="container px-lg-5">
                    <div
                        className={`navbar-translate align-items-center col-12 col-lg-7 col-xl-6 p-0 ${articlePage
                            ? "justify-content-end justify-content-lg-start d-none d-lg-flex"
                            : "justify-content-between justify-content-lg-start d-flex"
                            }`}
                    >
                        {
                            isMobile ?
                                <div className="d-flex justify-content-between align-items-center">
                                    <NavbarBrand
                                        href="https://linky.biz/"
                                        target="_blank"
                                        id="navbar-brand"
                                        className={`col-4 col-lg-3 col-xl-3 text-right m-0 p-0 ${articlePage ? "d-none d-lg-block" : ""
                                            }`}
                                    >
                                        {/* Now Ui Kit */}
                                        <img
                                            className="navbar-brand-logo"
                                            src={linky_logo}
                                            alt="logo"
                                            width="100%"
                                        // style={{ width: "60%" }}
                                        />
                                    </NavbarBrand>
                                    <span className="mr-3"><GoogleTranslate forMobile={true} /></span>
                                    <NavLink
                                        to={"/"}
                                        tag={Link}
                                        className="d-none d-lg-block "
                                    >
                                        Help Center
                                    </NavLink>
                                </div> : <>
                                    <NavbarBrand
                                        href="https://linky.biz/"
                                        target="_blank"
                                        id="navbar-brand"
                                        className={`col-4 col-lg-3 col-xl-3 text-right m-0 p-0 ${articlePage ? "d-none d-lg-block" : ""
                                            }`}
                                    >
                                        {/* Now Ui Kit */}
                                        <img
                                            className="navbar-brand-logo"
                                            src={linky_logo}
                                            alt="logo"
                                            width="100%"
                                        // style={{ width: "60%" }}
                                        />
                                    </NavbarBrand>
                                    <NavLink
                                        to={"/"}
                                        tag={Link}
                                        className="d-none d-lg-block "
                                    >
                                        Help Center
                                    </NavLink>
                                </>
                        }

                        {/* <UncontrolledTooltip target="#navbar-brand">
              Designed by Invision. Coded by Creative Tim
            </UncontrolledTooltip> */}

                        <button
                            className="navbar-toggler navbar-toggler p-0"
                            onClick={() => {
                                document.documentElement.classList.toggle(
                                    "nav-open"
                                );
                                setCollapseOpen(!collapseOpen);
                            }}
                            aria-expanded={collapseOpen}
                            type="button"
                        >
                            <span
                                className={`navbar-toggler-bar top-bar ${articlePage && "black-bars"
                                    }`}
                            ></span>
                            <span
                                className={`navbar-toggler-bar middle-bar ${articlePage && "black-bars"
                                    }`}
                            ></span>
                            <span
                                className={`navbar-toggler-bar bottom-bar ${articlePage && "black-bars"
                                    }`}
                            ></span>
                        </button>
                    </div>

                    <Collapse
                        className="justify-content-end navwth100"
                        isOpen={collapseOpen}
                        navbar
                    >
                        <Nav
                            navbar
                        // style={{width:'340px'}}
                        >
                            <NavItem>
                                <NavLink
                                    href="https://linky.biz"
                                //  to="/index" tag={Link}
                                >
                                    Go to linky.biz{" "}
                                    <i
                                        style={{ paddingLeft: "2px" }}
                                        className="fas fa-external-link-alt"
                                    ></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="https://linky.biz/contact-us">
                                    Contact us
                                </NavLink>
                            </NavItem>
                            {!isMobile && <NavItem>
                                <GoogleTranslate />
                            </NavItem>}
                            {/* <NavItem>
                <NavLink
                  href="https://twitter.com/CreativeTim?ref=creativetim"
                  target="_blank"
                  id="twitter-tooltip"
                >
                  <i className="fab fa-twitter"></i>
                  <p className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
                <UncontrolledTooltip target="#twitter-tooltip">
                  Follow us on Twitter
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.facebook.com/CreativeTim?ref=creativetim"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Like us on Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  Follow us on Instagram
                </UncontrolledTooltip>
              </NavItem> */}
                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
        </>
    );
}

export default ExamplesNavbar;
