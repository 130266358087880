export let api = {
    // helptopics from admin
    // for test 
    fetchHelptopics: "backendpai/helptopics/fetchHelptopics",

    fetchHelptopicsQA: "backendpai/helptopics/fetchHelptopics_questions",
    fetchHelpQuestion: "backendpai/helptopics/fetchHelpquestion",
    fetch_answer: "backendpai/helptopics/fetch_answer",
    // helpFeedback saving n mailing
    saveFeedback: "helpFeedbackApi/saveFeedbackData",
};
export default api;
