import React, { useState, createContext, useEffect } from "react";
import client from "../src/utils/HTTPClient";
import api from "../src/utils/apilist";
import { toast } from "react-toastify";

const InitialState = {
}

export const Context = createContext();
const Store = ({ children }) => {
    const [state, setState] = useState(InitialState);

    const fetchHelpData = () => {
        const helpData = {};
        client.post(api.fetchHelptopicsQA, helpData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    try {
                        if (!response.error) {
                            // console.log("helpData ==>", response.result.data);
                            // sethelpTopic(response.result.data);
                            let result = response.result.data;
                            let questions = response.result.questions;

                            let data = [];
                            for (let index = 0; index < result.length; index++) {
                                let element = result[index]
                                let resultQA = questions.filter((a) => a.fk_helptopic_id == element._id) || [];
                                // element.questions = resultQA ? resultQA : []
                                // console.log(resultQA, "resultQA")

                                let resQA = [];
                                let subTopic = element.sub_topic
                                for (let i = 0; i < subTopic.length; i++) {
                                    let elem = subTopic[i]
                                    let resultQAS = questions.filter((a)=> a.fk_helptopic_id ==elem._id) || [];
                                    resQA.push({ _id: elem._id, title: elem.title, questions: resultQAS });
                                }

                                data.push({ _id: element._id, main_topic: element.main_topic, description: element.description, icon_image: element.icon_image, questions: resultQA, sub_topic: resQA });
            
                            }

                            setState(data);

                        } else {
                            console.log(response.message);
                        }
                    } catch (error) {
                        console.log(error)
                        toast.error("error occured");
                    }
                } else {
                    toast.error("internal error occured");
                }
            } else {
                toast.error(error);
            }
            // console.log(helpTopic);
        });
    };
    useEffect(() => {
        fetchHelpData();
    }, []);
    return (
        <Context.Provider value={[state, setState]}>
            {children}
        </Context.Provider>
    );
};

export default Store;
