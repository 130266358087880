import DefaultFooter from "components/Footers/DefaultFooter";
import React, { useState, useEffect, useRef } from "react";
import ArticleNavbar from "components/Navbars/ArticleNavbar";
import LandingPageHeader from "components/Headers/LandingPageHeader";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import "../../components/ArticlesComp/style.scss";
import { Collapse } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ArticlesContentBody from "../../components/ArticlesComp/ArticlesContentBody";
// import ArticlesData from "../../components/ArticlesComp/ArticlesData";
import SideBarList from "components/ArticlesComp/SideBarList";

import helper from "helpers/helpers";
import ArticlesSubTopicBody from "components/ArticlesComp/ArticlesSubTopicBody";
import GoogleTranslate from "components/Navbars/GoogleTranslate";

import api from '../../utils/apilist'
import client from '../../utils/HTTPClient'

import { HashLoader } from "react-spinners";

const HelpArticlesPage = ({ item, data, questions, main, refId }) => {
    // console.log("questions==>", item);
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    const [widths, setWidths] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidths(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const [helpData, sethelpData] = useState([]);
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState("");
    const [searchResult, setSearchResult] = useState(null);

    let menuRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!menuRef.current.contains(e.target)) {
                setVisible(false);
            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        };
    }, []);

    useEffect(() => {
        // setLoading(true);
        if (!data) {
            // setLoading(true);/
        }
        sethelpData(data);
    }, data);


    const [topic, settopic] = useState([]);
    const [mainQuestions, setMainQuestions] = useState([]);

    const fetchQuestions = async () => {
        client.post(api.fetchHelpQuestion, { subTopicId: item._id }, async (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setMainQuestions(response.result.data);
                            settopic(response.result.topic);
                            setLoading(false)

                        }
                    } catch {

                    }
                }
            }
        })
    }
    useEffect(() => {
        fetchQuestions()
    }, [])
    const handleClick = () => {
        setOpen(!open);
    };
    const toggle = () => {
        setVisible(!visible);
    };

    const searchParam = [];
    if (helpData.length > 0) {
        helpData.map((i) => {
            if (i.questions.length > 0) {
                i.questions.map((item) => {
                    searchParam.push({
                        main: i.main_topic,
                        questions: item.questions,
                        sub_topic: "",
                    });
                });
            }
            if (i.sub_topic.length > 0) {
                i.sub_topic.map((elem) => {
                    if (elem.questions.length > 0) {
                        elem.questions.map((newItem) => {
                            searchParam.push({
                                main: i.main_topic,
                                questions: newItem.questions,
                                sub_topic: elem.title,
                            });
                        });
                    }
                });
            }
            searchParam.push({
                main: i.main_topic,
                questions: "",
                sub_topic: "",
            });
        });
    }

    // const Search = (query) => {
    //     setQuery(query);
    //     let filtered = [];
    //     searchParam.find((item, i) => {
    //         if (query.length > 0) {
    //             if (
    //                 item.main.toLowerCase().includes(query.toLowerCase()) ||
    //                 item.questions.toLowerCase().includes(query.toLowerCase())
    //             ) {
    //                 filtered.push({
    //                     main_topic: searchParam[i].main,
    //                     questions: searchParam[i].questions,
    //                     sub_topic: searchParam[i].sub_topic,
    //                 });
    //             }
    //         }
    //     });
    //     setSearchResult(filtered);
    // };
    let str;
    const Search = (query) => {
        setQuery(query);
        str = query.split(" ");
        let filtered = [];
        searchParam.find((item, i) => {
            str.map((elem) => {
                if (elem.length > 0) {
                    if (
                        item.main.toLowerCase().includes(elem.toLowerCase()) ||
                        item.questions
                            .toLowerCase()
                            .includes(elem.toLowerCase())
                    ) {
                        filtered.push({
                            main_topic: searchParam[i].main,
                            questions: searchParam[i].questions,
                            sub_topic: searchParam[i].sub_topic,
                        });
                    }
                }
            });
        });
        filtered = filtered.filter(
            (value, index, self) =>
                index ===
                self.findIndex(
                    (t) =>
                        t.main_topic === value.main_topic &&
                        t.sub_topic === value.sub_topic &&
                        t.questions === value.questions
                )
        );
        setSearchResult(filtered);
    };

    const history = useHistory();
    const location = useLocation();
    const subClick = (item, i) => {
        if (location.pathname !== `${helper.urlencoder(item.main_topic)}`) {
            // navigateToSec(i);
            history.push(`/${helper.urlencoder(item.main_topic)}`);
        }
        setTimeout(() => {
            navigateToSec(i);
        }, 500);
    };
    const navigateToSec = (i) => {
        window.location.href = `#${helper.urlencoder(i.title)}`;
    };

    // const controls = useRef();
    // const points = useRef();
    // const [fixedSideBar, setFixedSideBar] = useState(false);
    // useEffect(() => {
    // const initialTop = document.getElementById('controls').getBoundingClientRect().top;
    // const initialTop = controls.current.getBoundingClientRect().top;
    //     console.log({ initialTop });
    // const handleScroll = () => {
    //     setFixedSideBar(window.scrollY > initialTop - 90);
    // };
    // window.addEventListener("scroll", handleScroll);
    // return () => {
    //     window.removeEventListener("scroll", handleScroll);
    // };
    // }, []);

    return (
        <div>
            {widths > 992 &&
                <ArticleNavbar articlePage />
            }
            <div className="wrapper">
                <LandingPageHeader
                    height={"70px"}
                    // height={"300px"}
                    searchWidth={"1092px"}
                    articlePage
                />
                {!loading && helpData.length > 0 ? (
                    <div>
                        <div className="articles-comp-styles p-lg-0">
                            <section className="container-lg px-0 px-lg-2 py-lg-4 pb-5">
                                <div
                                    style={{
                                        background: "#fff",
                                        // height: "50px",
                                        width: "100%",
                                        // padding:'20px',
                                    }}
                                    className=" d-lg-none"
                                    ref={menuRef}
                                >
                                    <div
                                        className={`${open ? "d-block" : "d-none"
                                            }`}
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            top: "0",
                                            // height: "100vh",
                                            backgroundColor: "white",
                                            paddingTop: "10px",
                                            zIndex: "1004",
                                        }}
                                    >
                                        <div className="d-flex align-items-center justify-content-center">
                                            <SearchIcon />
                                            <input
                                                type="text "
                                                placeholder="Search for answers"
                                                value={query}
                                                onChange={(e) =>
                                                    Search(e.target.value)
                                                }
                                                style={{
                                                    height: "50px",
                                                    background: "transparent",
                                                    fontSize: "1rem",
                                                    border: "none",
                                                    width: "80%",
                                                    padding: ".75em",
                                                }}
                                            />
                                            <CloseIcon
                                                onClick={handleClick}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                        {query &&
                                            (searchResult.length > 0 ? (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        // padding: "0 50px",
                                                    }}
                                                >
                                                    <ul
                                                        className="search-results search-results-mob"
                                                        style={{
                                                            listStyle: "none",
                                                            padding: "0",
                                                        }}
                                                    >
                                                        {searchResult.length >
                                                            0 &&
                                                            searchResult.map(
                                                                (i, n) => (
                                                                    <li
                                                                        key={n}
                                                                        style={{
                                                                            padding:
                                                                                "10px 50px",
                                                                        }}
                                                                    >
                                                                        <Link
                                                                            to={
                                                                                i.questions
                                                                                    ? helper.urlencoder(
                                                                                        i.questions
                                                                                    )
                                                                                    : helper.urlencoder(
                                                                                        i.main_topic
                                                                                    )
                                                                            }
                                                                            style={{
                                                                                textDecoration:
                                                                                    "none",
                                                                            }}
                                                                        >
                                                                            <p
                                                                                className="p-0 m-0"
                                                                                style={{
                                                                                    fontWeight:
                                                                                        "normal",
                                                                                    color: "#484848",
                                                                                    fontSize:
                                                                                        "16px",
                                                                                    lineHeight:
                                                                                        "30px",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    i.questions
                                                                                }
                                                                            </p>
                                                                            <h5
                                                                                className="p-0 m-0"
                                                                                style={{
                                                                                    color: "#d23f99",
                                                                                    fontSize:
                                                                                        ".875rem",
                                                                                    fontWeight:
                                                                                        "600",
                                                                                    lineHeight:
                                                                                        "1.5rem",
                                                                                    fontFamily:
                                                                                        "Open Sans, sans-seriff",
                                                                                }}
                                                                            >
                                                                                {i
                                                                                    .sub_topic
                                                                                    .length >
                                                                                    0 ? (
                                                                                    <>
                                                                                        {
                                                                                            i.main_topic
                                                                                        }
                                                                                        <ChevronRightIcon
                                                                                            sx={{
                                                                                                color: "#d23f99",
                                                                                            }}
                                                                                        />
                                                                                        {
                                                                                            i.sub_topic
                                                                                        }
                                                                                    </>
                                                                                ) : (
                                                                                    i.main_topic
                                                                                )}
                                                                            </h5>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            )}
                                                    </ul>
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        padding: "0 50px",
                                                    }}
                                                >
                                                    No result found
                                                </div>
                                            ))}
                                    </div>
                                    <div
                                        style={{
                                            margin: "10px 0",
                                            height: "56px",
                                            padding: "0 1.25rem",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            zIndex: "1003",
                                            position: "relative",
                                        }}
                                    >
                                        <div
                                            style={{
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                                zIndex: "1000",
                                                userSelect: "none",
                                            }}
                                            onClick={() => toggle()}
                                        >
                                            {item.main_topic}
                                            {visible ? (
                                                <ExpandMore
                                                    sx={{ color: "#d23f99" }}
                                                />
                                            ) : (
                                                <ChevronRightIcon
                                                    sx={{ color: "#d23f99" }}
                                                />
                                            )}
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <span className="mr-3">
                                                <GoogleTranslate forMobile={true} />
                                            </span>
                                            <SearchIcon
                                                onClick={handleClick}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                    </div>
                                    <Collapse
                                        in={visible}
                                        style={{
                                            position: "absolute",
                                            zIndex: "20",
                                            width: "100%",
                                            background: "white",
                                            // height: "100vh !important",
                                        }}
                                        className="mob-list-collapse"
                                    // ref={menuRef}
                                    >
                                        <ul>
                                            {helpData.map((item, index) => (
                                                <div key={index}>
                                                    <li className="main_topic">
                                                        <Link
                                                            to={`/${helper.urlencoder(
                                                                item.main_topic
                                                            )}`}
                                                        >
                                                            {item.main_topic}
                                                        </Link>
                                                        {item.sub_topic.length >
                                                            0 && (
                                                                <ul className="sub_topic">
                                                                    {item.sub_topic.map(
                                                                        (i, no) => (
                                                                            <li
                                                                                className="sub_topic_item"
                                                                                key={
                                                                                    no
                                                                                }
                                                                                onClick={() =>
                                                                                    subClick(
                                                                                        item,
                                                                                        i
                                                                                    )
                                                                                }
                                                                            >
                                                                                <a
                                                                                // href={`#${helper.urlencoder(
                                                                                //     i.title
                                                                                // )}`}
                                                                                >
                                                                                    {
                                                                                        i.title
                                                                                    }
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            )}
                                                    </li>
                                                </div>
                                            ))}
                                        </ul>
                                    </Collapse>
                                </div>

                                <div className="content-container ">
                                    <Row className=" justify-content-center">
                                        <Col
                                            // lg="12"
                                            xl="11"
                                            className="py-4 col-xxl-10"
                                            style={{
                                                borderRadius: "5px",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <div
                                                className="w-100 d-md-none px-2 mb-1"
                                                style={{
                                                    padding: "0 0 0.8rem",
                                                }}
                                            >
                                                <Link
                                                    to={"/"}
                                                    style={{
                                                        fontSize: ".75rem",
                                                    }}
                                                >
                                                    {" "}
                                                    <ChevronLeftIcon />
                                                    Back to home
                                                </Link>
                                            </div>
                                            {helpData.length > 0 && (
                                                <Breadcrumb className="px-2 d-none d-md-block">
                                                    <BreadcrumbItem>
                                                        <Link to={"/"}>
                                                            Help Center
                                                        </Link>
                                                    </BreadcrumbItem>

                                                    {helpData.length > 0 && (
                                                        <BreadcrumbItem>
                                                            {item.main_topic}
                                                        </BreadcrumbItem>
                                                    )}
                                                </Breadcrumb>
                                            )}

                                            <Row className="justify-content-between pt-md-3 pb-4">
                                                <Col
                                                    md="4"
                                                    lg="4"
                                                    xl="3"
                                                    className="d-none d-lg-block"
                                                    // ref={controls}
                                                    id="controls"
                                                >
                                                    <div
                                                    // id="controls"
                                                    // className={
                                                    //     fixedSideBar &&
                                                    //     `custom-fixed-sideBar`
                                                    // }
                                                    >
                                                        <SideBarList
                                                            list={helpData}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col
                                                    xs="11"
                                                    md="8"
                                                    lg="8"
                                                    className="content-body-section px-4 mx-auto mx-md-0"
                                                    style={{
                                                        height: "500px !important",
                                                    }}
                                                >
                                                    <div>
                                                        {main &&
                                                            helpData.length >
                                                            0 && (
                                                                <ArticlesContentBody
                                                                    pageData={
                                                                        item
                                                                    }
                                                                    topic={topic}
                                                                    mainQuestions={mainQuestions}
                                                                />
                                                            )}
                                                        {questions && (
                                                            <>
                                                                <p
                                                                    className="go-back-link"
                                                                    onClick={
                                                                        history.goBack
                                                                    }
                                                                >
                                                                    <ChevronLeftIcon />
                                                                    go back
                                                                </p>
                                                                <ArticlesSubTopicBody
                                                                    pageContent={
                                                                        item
                                                                    }
                                                                    data={
                                                                        helpData
                                                                    }
                                                                    main_id={
                                                                        refId
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </section>
                        </div>
                        <DefaultFooter white />
                    </div>
                ) : (
                    <div
                        className="d-flex"
                        style={{
                            height: "100vh",
                            width: "100vw",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: "100",
                        }}
                    >
                        <HashLoader
                            color={"#d23f99"}
                            loading={true}
                            size={50}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default HelpArticlesPage;
