import React, { useContext, useState, useEffect } from "react";
import { Context } from "Store";
import { Route, Switch } from "react-router-dom";

import LandingPage from "views/examples/LandingPage.js";
import HelpArticlesPage from "pages/helpArticlesPage/HelpArticlesPage.jsx";

import helper from "../src/helpers/helpers";
// import ArticlesSubTopicBody from "components/ArticlesComp/ArticlesSubTopicBody";

const Root = () => {
    const [state] = useContext(Context);
    const [data, setData] = useState([]);
    const [routeData, setRouteData] = useState(null);
    const [subRouteData, setSubRouteData] = useState(null);

    let tempArr = [];
    let subTempArr = [];
    const routeMapFunc = () => {
        if (data.length > 0) {
            data.map((item) => {
                if (item.questions.length > 0) {
                    item.questions.map((i, no) => {
                        let quesUrl = helper.urlencoder(i.questions);
                        let dataa = {};
                        dataa.quesUrl = quesUrl;
                        dataa.topic = i.questions;
                        dataa.item = i.answer;
                        dataa.main_topic = item.main_topic;
                        dataa.refId = item._id;
                        dataa._id = i._id;
                        tempArr.push(dataa);
                    });
                }

                if (item.sub_topic.length > 0) {
                    item.sub_topic.map((elem) => {
                        // console.log("working", elem);
                        elem.questions.length > 0 &&
                            elem.questions.map((i, no) => {
                                let subUrl = helper
                                    .urlencoder(i.questions)
                                    .replace("?", "");
                                // let subUrl = helper.urlencoder(
                                //     `subquestion ${no + 1}`
                                // );
                                // console.log("url==>", subUrl);
                                let subData = {};
                                subData.subUrl = subUrl;
                                subData.topic = i.questions;
                                subData.item = i.answer;
                                subData._id = i._id;
                                subData.main_topic = item.main_topic;
                                subData.refId = item._id;

                                subTempArr.push(subData);
                                // console.log("subDATA==>", subData);
                            });
                    });
                }
            });
        }
        setRouteData(tempArr);
        setSubRouteData(subTempArr);
    };

    useEffect(() => {
        setData(state);
    }, [state]);

    useEffect(() => {
        routeMapFunc();
    }, [data]);
    return (
        <div>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={(props) => <LandingPage {...props} />}
                />

                {data.length > 0 &&
                    data.map((item, index) => {
                        let pathName = helper.urlencoder(item.main_topic);

                        {
                            /* console.log("path=>", pathName); */
                        }

                        return (
                            <Route
                                key={index}
                                path={`/${pathName}`}
                                component={() => (
                                    <HelpArticlesPage
                                        item={item}
                                        data={data}
                                        main
                                    />
                                )}
                            />
                        );
                    })}

                {routeData &&
                    routeData.map((routee, ind) => {
                        let stringg = `${routee.quesUrl}`;
                        {
                            /* console.log('string=>',stringg) */
                        }
                        return (
                            <Route
                                key={ind}
                                path={`/${stringg}`}
                                component={() => (
                                    <HelpArticlesPage
                                        data={data}
                                        item={routee}
                                        refId={routee.refId}
                                        questions
                                    />
                                )}
                            />
                        );
                    })}
                {/* {console.log("data==>", subRouteData)} */}
                {subRouteData &&
                    subRouteData.map((r, ind) => {
                        let str = `${r.subUrl}`;

                        {
                            /* console.log('refId ==>',r) */
                        }

                        return (
                            <Route
                                key={ind}
                                path={`/${str}`}
                                component={() => (
                                    <HelpArticlesPage
                                        data={data}
                                        item={r}
                                        questions
                                        refId={r.refId}
                                    />
                                )}
                            />
                        );
                    })}
                {/* {subRouteData &&
                    subRouteData.map((i, no) => {
                        let str = `${i.subUrl}`;
                        return (
                            <Route
                                key={no}
                                path={`/${str}/:section`}
                                component={() => <HelpArticlesPage />}
                            />
                        );
                    })} */}
                {/* <Route
                    path={`/question-1`}
                    component={() => <ArticlesSubTopicBody />}
                /> */}

                {/* <Redirect from="*" to="/" /> */}
            </Switch>
        </div>
    );
};

export default Root;
