import React, { useState, useEffect, useContext } from "react";
import helper from "helpers/helpers";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Input, InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { Context } from "Store";

const SearchBarComp = () => {
    const [firstFocus, setFirstFocus] = React.useState(false);
    const [query, setQuery] = useState("");
    const [searchResult, setSearchResult] = useState(null);
    const [state] = useContext(Context);
    const searchParam = [];
    if (state.length > 0) {
        state.map((i) => {
            if (i.questions.length > 0) {
                i.questions.map((item) => {
                    searchParam.push({
                        main: i.main_topic,
                        questions: item.questions,
                        sub_topic: "",
                    });
                });
            }
            if (i.sub_topic.length > 0) {
                i.sub_topic.map((elem) => {
                    if (elem.questions.length > 0) {
                        elem.questions.map((newItem) => {
                            searchParam.push({
                                main: i.main_topic,
                                questions: newItem.questions,
                                sub_topic: elem.title,
                            });
                        });
                    }
                });
            }
            searchParam.push({
                main: i.main_topic,
                questions: "",
                sub_topic: "",
            });
        });
    }
    // console.log(searchParam);

    let str;
    const Search = (query) => {
        setQuery(query);
        str = query.split(" ");
        let filtered = [];
        searchParam.find((item, i) => {
            str.map((elem) => {
                if (elem.length > 0) {
                    if (
                        item.main.toLowerCase().includes(elem.toLowerCase()) ||
                        item.questions
                            .toLowerCase()
                            .includes(elem.toLowerCase())
                    ) {
                        filtered.push({
                            main_topic: searchParam[i].main,
                            questions: searchParam[i].questions,
                            sub_topic: searchParam[i].sub_topic,
                        });
                    }
                }
            });
            // if (query.length > 0) {
            //     if (
            //         item.main.toLowerCase().includes(query.toLowerCase()) ||
            //         item.questions.toLowerCase().includes(query.toLowerCase())
            //     ) {
            //         filtered.push({
            //             main_topic: searchParam[i].main,
            //             questions: searchParam[i].questions,
            //             sub_topic: searchParam[i].sub_topic,
            //         });
            //     }
            // }
        });
        // str.map((item) => {
        //     let pattern = new RegExp(`${item}`, "gi");
        //     filtered.map((child) => {
        //         if (child.main_topic.match(item)) {
        //             child.main_topic.replace(
        //                 pattern,
        //                 (match) => `<mark>${match}</mark>`
        //             );
        //         }
        //     });
        // });
        filtered = filtered.filter(
            (value, index, self) =>
                index ===
                self.findIndex(
                    (t) =>
                        t.main_topic === value.main_topic &&
                        t.sub_topic === value.sub_topic &&
                        t.questions === value.questions
                )
        );
        // console.log("filterd==>", da);

        setSearchResult(filtered);
        // console.log("filtered===>", query, filtered);
        // console.log("str==>", str);
    };

    const higlightResult = () => {
        str.map((item) => {
            let pattern = new RegExp(`${item}`, "gi");
            searchResult.map((i) => {
                if (i.main_topic.match(item)) {
                    // return
                    // `<b>${i.main_topic}</b>`
                    i.main_topic.replace(
                        pattern,
                        (match) => `<mark>${match}</mark>`
                    );
                }
                // if()
            });
        });
    };
    return (
        <div
            className="w-100"
            style={{
                position: "relative",
                // left:'25%',
                // top:'20%',
                // maxWidth: `650px`,
                // zIndex: "1001",
            }}
        >
            <div
                className="container p-0"
                // ref={controls}
            >
                <div className="text-center">
                    <InputGroup
                        className={
                            "input-lg mx-auto mb-0" +
                            (firstFocus ? " input-group-focus" : "")
                        }
                        // style={{ maxWidth: { searchWidth } }}
                        style={{ position: "relative" }}
                    >
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText
                                style={{
                                    borderRadius: "5px 0 0 5px",
                                    border: "none",
                                }}
                            >
                                <i className="fas fa-search"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Search for answers"
                            type="text"
                            // onFocus={() => setFirstFocus(true)}
                            onBlur={() => setFirstFocus(false)}
                            style={{
                                borderRadius: "0 5px 5px 0",
                                border: "none",
                            }}
                            value={query}
                            onChange={(e) => Search(e.target.value)}
                        ></Input>
                        {query && (
                            <InputGroupAddon addonType="append">
                                <InputGroupText
                                    style={{
                                        borderRadius: "0 5px 5px 0",
                                        position: "absolute",
                                        right: "0",
                                        top: "0",
                                        bottom: "0",
                                        zIndex: "50",
                                        cursor: "pointer",
                                        border: "none",
                                    }}
                                    onClick={() => setQuery(null)}
                                >
                                    <i className="fa fa-times"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                        )}
                    </InputGroup>
                </div>
                {query &&
                    (searchResult.length > 0 ? (
                        <ul
                            className="search-results search-results-mob"
                            style={{
                                listStyle: "none",
                                position: "absolute",
                                background: "white",
                                padding: "0",
                                overflowY: "scroll",
                                scrollbarWidth: "thin",
                                maxHeight: "500px",
                                // top: `${articlePage ? "80%" : "80%"}`,
                                zIndex: "-1",
                                // bottom: `${
                                //     articlePage ? "-125px" : "-70px"
                                // }`,
                                // left: "50%",
                                // transform: "translateX(-50%)",
                                color: "black",
                                boxShadow: "0 12px 22px  gray",
                                borderRadius: "0 0 5px 5px",
                                // width: `${
                                //     articlePage ? "1038px" : "638px"
                                // }`,
                                width: "100%",
                                textAlign: "left",
                                marginInline: "auto",
                                marginTop: "-14px",
                            }}
                        >
                            {searchResult.length > 0 &&
                                searchResult.map((i, n) => (
                                    <li
                                        key={n}
                                        style={{
                                            padding: "10px 50px",
                                        }}
                                    >
                                        <Link
                                            to={
                                                i.questions
                                                    ? helper.urlencoder(
                                                          i.questions
                                                      )
                                                    : helper.urlencoder(
                                                          i.main_topic
                                                      )
                                            }
                                            style={{
                                                textDecoration: "none",
                                            }}
                                        >
                                            <p
                                                className="p-0 m-0"
                                                style={{
                                                    fontWeight: "normal",
                                                    color: "#484848",
                                                    fontSize: "16px",
                                                    lineHeight: "30px",
                                                }}
                                            >
                                                {i.questions}
                                            </p>
                                            <h5
                                                className="p-0 m-0"
                                                style={{
                                                    color: "#d23f99",
                                                    fontSize: ".875rem",
                                                    // fontWeight: "600",
                                                    lineHeight: "1.5rem",
                                                    fontFamily:
                                                        "Open Sans, sans-seriff",
                                                }}
                                            >
                                                {i.sub_topic.length > 0 ? (
                                                    <>
                                                        {i.main_topic}
                                                        <ChevronRightIcon
                                                            sx={{
                                                                color: "#d23f99",
                                                            }}
                                                        />
                                                        {i.sub_topic}
                                                    </>
                                                ) : (
                                                    i.main_topic
                                                )}
                                            </h5>
                                        </Link>
                                    </li>
                                ))}
                        </ul>
                    ) : (
                        <div
                            style={{
                                position: "absolute",
                                background: "white",
                                padding: "20px 50px",
                                zIndex: "-1",
                                // bottom: `${articlePage ? "-40px" : "15px"}`,
                                // left: "50%",
                                // top: `${articlePage ? "85%" : "85%"}`,
                                // transform: "translateX(-50%)",
                                color: "black",
                                boxShadow: "0 12px 22px  gray",
                                borderRadius: "0 0 5px 5px",
                                // width: `${
                                //     articlePage ? "1038px" : "638px"
                                // }`,
                                width: "100%",
                                textAlign: "left",
                                marginTop: "-14px",
                            }}
                        >
                            No Result Found..
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default SearchBarComp;
