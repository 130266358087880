import React, { useEffect, useState } from "react";
import helper from "helpers/helpers";
import { Link } from "react-router-dom";
import api from '../../utils/apilist'
import client from '../../utils/HTTPClient'
import { HashLoader } from "react-spinners";

const ArticlesContentBody = ({ pageData, topic, mainQuestions }) => {
    const { main_topic, description, sub_topic } = pageData;
    const [visible, setVisible] = useState([]);
    // const [topic, settopic] = useState([]);
    const [loading, setLoading] = useState(false);

    // const [mainQuestions, setMainQuestions] = useState([]);
    // console.log('pageDATA::::', pageData)
    // const fetchQuestions = async () => {
    //     client.post(api.fetchHelpQuestion, { subTopicId: pageData._id }, async (error, response) => {
    //         if (!error) {
    //             if (typeof response.error !== "undefined") {
    //                 try {
    //                     if (!response.error) {
    //                         setMainQuestions(response.result.data);
    //                         settopic(response.result.topic);
    //                         setLoading(false)

    //                     }
    //                 } catch {

    //                 }
    //             }
    //         }
    //     })
    // }
    // useEffect(() => {
    //     fetchQuestions()
    // }, [])
    // useEffect(() => {
    //     setData(pageData);
    // }, [pageData]);


    // for main questions
    const [view, setView] = useState(false);
    function toggleSeeMore(e) {
        setView(!view);
    }


    // for sub-topic only
    const [toggledView, setToggledView] = useState(null);
    function toggleView(e) {
        setToggledView(e._id);
    }

    return (
        <div>
            <div>
                <h2>{main_topic}</h2>
                <h6 className="desc">{topic.description}</h6>
            </div>

            {mainQuestions.length > 0 && (
                <section className="sub_topic_content_body">
                    {pageData.sub_topic.length > 0 ? (
                        <>
                            {/* {pageData.questions
                                .slice(0, view ? pageData.questions.length : 4) */}
                            {mainQuestions && mainQuestions
                                .slice(0, view ? mainQuestions.length : 4)
                                .map((i, no) => (
                                    <div key={no}>
                                        <Link
                                            to={helper.urlencoder(i.questions)}
                                        >
                                            {i.questions}
                                        </Link>
                                    </div>
                                ))}
                            {/* {pageData.questions.length > 4 && */}
                            {mainQuestions.length > 4 &&
                                (!view ? (
                                    <p
                                        className="list-toggle mt-4"
                                        onClick={toggleSeeMore}
                                    >
                                        load more
                                    </p>
                                ) : (
                                    <p
                                        className="list-toggle mt-4"
                                        onClick={toggleSeeMore}
                                    >
                                        load less
                                    </p>
                                ))}
                        </>
                    ) : (
                        <>
                            {/* {pageData.questions.map((i, no) => ( */}
                            {mainQuestions.map((i, no) => (
                                <div key={no}>
                                    <Link
                                        to={helper.urlencoder(i.questions)}
                                    >
                                        {i.questions}
                                    </Link>
                                </div>
                            ))}
                        </>
                    )}
                </section>
            )}
            {pageData.sub_topic.length > 0 &&
                pageData.sub_topic.map((i, n) => {
                    let isVisible = i._id === toggledView;
                    return (
                        <section id={`${helper.urlencoder(i.title)}`} key={n}>
                            <h4>{i.title}</h4>
                            {i.questions.length > 0 &&
                                i.questions
                                    .slice(
                                        0,
                                        isVisible ? i.questions.length : 4
                                    )
                                    .map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <Link
                                                    to={helper.urlencoder(
                                                        item.questions
                                                    )}

                                                >
                                                    {item.questions}
                                                </Link>
                                            </div>
                                        );
                                    })}
                            {i.questions.length > 4 &&
                                (!isVisible ? (
                                    <p
                                        className="list-toggle mt-4"
                                        onClick={() => toggleView(i)}
                                    >
                                        load more
                                    </p>
                                ) : (
                                    <p
                                        className="list-toggle mt-4"
                                        onClick={() => toggleView(!i)}
                                    >
                                        load less
                                    </p>
                                ))}
                        </section>
                    );
                })}
            {/* {tempDATA.length > 0 &&
                tempDATA.map((i) => {
                    const isVisible = i._id === toggledVisible;
                    return (
                        <section key={i._id}>
                            <h4>{i.title}</h4>
                            {i.questions
                                .slice(0, isVisible ? i.questions.length : 2)
                                .map((item, ind) => {
                                    return (
                                        <div key={ind}>
                                            <p>{item.questions}</p>
                                        </div>
                                    );
                                })}

                            {i.questions.length > 2 ? (
                                !isVisible ? (
                                    <p
                                        className="list-toggle"
                                        onClick={() => toggleView(i)}
                                    >
                                        load more
                                    </p>
                                ) : (
                                    <p
                                        className="list-toggle"
                                        onClick={() => toggleView(!i)}
                                    >
                                        load less
                                    </p>
                                )
                            ) : (
                                ""
                            )}
                        </section>
                    );
                })} */}
        </div>
    );
};

export default ArticlesContentBody;
