import React, { useState, useContext, useEffect, useRef } from "react";

// reactstrap components
import {
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
} from "reactstrap";
import "../../assets/css/style.scss";
import { Context } from "Store";
import helper from "helpers/helpers";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// core components

function LandingPageHeader({
    height = "390px",
    searchWidth = "693px",
    title,
    articlePage,
}) {
    const [firstFocus, setFirstFocus] = React.useState(false);
    const [query, setQuery] = useState("");
    const [searchResult, setSearchResult] = useState(null);
    const [state] = useContext(Context);

    // fixed header
    // const controls = useRef();
    // const [fixedHeader, setFixedHeader] = useState(false);
    // useEffect(() => {
    //     const initialTop = controls.current.getBoundingClientRect().top;
    //     console.log({ initialTop });
    //     const handleScroll = () => {
    //         setFixedHeader(window.scrollY > initialTop - 75);
    //     };
    //     window.addEventListener("scroll", handleScroll);
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    // const [lastFocus, setLastFocus] = React.useState(false);

    // let pageHeader = React.createRef();
    // React.useEffect(() => {
    //   if (window.innerWidth > 991) {
    //     const updateScroll = () => {
    //       let windowScrollTop = window.pageYOffset / 3;
    //       pageHeader.current.style.transform =
    //         "translate3d(0," + windowScrollTop + "px,0)";
    //     };
    //     window.addEventListener("scroll", updateScroll);
    //     return function cleanup() {
    //       window.removeEventListener("scroll", updateScroll);
    //     };
    //   }
    // });
    // console.log("whole DATA=>", state);
    const searchParam = [];
    if (state.length > 0) {
        state.map((i) => {
            if (i.questions.length > 0) {
                i.questions.map((item) => {
                    searchParam.push({
                        main: i.main_topic,
                        questions: item.questions,
                        sub_topic: "",
                    });
                });
            }
            if (i.sub_topic.length > 0) {
                i.sub_topic.map((elem) => {
                    if (elem.questions.length > 0) {
                        elem.questions.map((newItem) => {
                            searchParam.push({
                                main: i.main_topic,
                                questions: newItem.questions,
                                sub_topic: elem.title,
                            });
                        });
                    }
                });
            }
            searchParam.push({
                main: i.main_topic,
                questions: "",
                sub_topic: "",
            });
        });
    }
    // console.table(searchParam);

    // const Search = (query) => {
    //     setQuery(query);
    //     let filtered = [];
    //     searchParam.find((item, i) => {
    //         if (query.length > 0) {
    //             if (
    //                 item.main.toLowerCase().includes(query.toLowerCase()) ||
    //                 item.questions.toLowerCase().includes(query.toLowerCase())
    //             ) {
    //                 filtered.push({
    //                     main_topic: searchParam[i].main,
    //                     questions: searchParam[i].questions,
    //                     sub_topic: searchParam[i].sub_topic,
    //                 });
    //             }
    //         }
    //     });
    //     setSearchResult(filtered);

    // };
    let str;
    const Search = (query) => {
        setQuery(query);
        str = query.split(" ");
        let filtered = [];
        searchParam.find((item, i) => {
            str.map((elem) => {
                if (elem.length > 0) {
                    if (
                        item.main.toLowerCase().includes(elem.toLowerCase()) ||
                        item.questions
                            .toLowerCase()
                            .includes(elem.toLowerCase())
                    ) {
                        filtered.push({
                            main_topic: searchParam[i].main,
                            questions: searchParam[i].questions,
                            sub_topic: searchParam[i].sub_topic,
                        });
                    }
                }
            });
            // if (query.length > 0) {
            //     if (
            //         item.main.toLowerCase().includes(query.toLowerCase()) ||
            //         item.questions.toLowerCase().includes(query.toLowerCase())
            //     ) {
            //         filtered.push({
            //             main_topic: searchParam[i].main,
            //             questions: searchParam[i].questions,
            //             sub_topic: searchParam[i].sub_topic,
            //         });
            //     }
            // }
        });
        // str.map((item) => {
        //     let pattern = new RegExp(`${item}`, "gi");
        //     filtered.map((child) => {
        //         if (child.main_topic.match(item)) {
        //             child.main_topic.replace(
        //                 pattern,
        //                 (match) => `<mark>${match}</mark>`
        //             );
        //         }
        //     });
        // });
        filtered = filtered.filter(
            (value, index, self) =>
                index ===
                self.findIndex(
                    (t) =>
                        t.main_topic === value.main_topic &&
                        t.sub_topic === value.sub_topic &&
                        t.questions === value.questions
                )
        );
        // console.log("filterd==>", da);

        setSearchResult(filtered);
        // console.log("filtered===>", query, filtered);
        // console.log("str==>", str);
    };

    return (
        <>
            <div
                className={`
            page-header page-header-small 
                ${articlePage ? "d-none d-lg-block" : ""} 
                ${articlePage && "custom-fixed-header"}
                `}
                style={{ minHeight: `${height}`, overflow: "unset" }}
                // ref={controls}
            >
                <div>
                    {/* changing position relative */}
                    <div
                        className="page-header-image"
                        style={{
                            // backgroundColor: '#d23f99'
                            // backgroundColor: '#4E5160'
                            backgroundImage:
                                "url(" +
                                require("assets/img/help-banner.png").default +
                                ")",
                        }}
                        // ref={pageHeader}
                    ></div>
                    {!articlePage && (
                        <div
                            className="content-center"
                            style={{
                                maxWidth: `${searchWidth}`,
                                zIndex: "1001",
                                // bottom: `${articlePage ? "-35px" : "0"}`,
                                // top: `${!articlePage && "72%"}`,
                                // bottom: "0",
                                top: `${!articlePage ? "65%" : "75%"}`,
                            }}
                        >
                            <div
                                className="container"
                                // ref={controls}
                            >
                                {title && (
                                    <h1
                                        className="title p-0 mb-3"
                                        style={{
                                            fontSize: "2rem",
                                            fontWeight: "500",
                                        }}
                                    >
                                        How can we help you today?
                                    </h1>
                                )}
                                <div className="text-center">
                                    <InputGroup
                                        className={
                                            "input-lg mx-auto" +
                                            (firstFocus
                                                ? " input-group-focus"
                                                : "")
                                        }
                                        // style={{ maxWidth: { searchWidth } }}
                                        style={{ position: "relative" }}
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText
                                                style={{
                                                    borderRadius: "5px 0 0 5px",
                                                    border: "none",
                                                }}
                                            >
                                                <i className="fas fa-search"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Search for answers"
                                            type="text"
                                            // onFocus={() => setFirstFocus(true)}
                                            onBlur={() => setFirstFocus(false)}
                                            style={{
                                                borderRadius: "0 5px 5px 0",
                                                border: "none",
                                            }}
                                            value={query}
                                            onChange={(e) =>
                                                Search(e.target.value)
                                            }
                                        ></Input>
                                        {query && (
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText
                                                    style={{
                                                        borderRadius:
                                                            "0 5px 5px 0",
                                                        position: "absolute",
                                                        right: "0",
                                                        top: "0",
                                                        bottom: "0",
                                                        zIndex: "50",
                                                        cursor: "pointer",
                                                        border: "none",
                                                    }}
                                                    onClick={() =>
                                                        setQuery(null)
                                                    }
                                                >
                                                    <i className="fa fa-times"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        )}
                                    </InputGroup>
                                </div>
                                {query &&
                                    (searchResult.length > 0 ? (
                                        <ul
                                            className="search-results search-results-mob"
                                            style={{
                                                listStyle: "none",
                                                // position: "absolute",
                                                background: "white",
                                                padding: "0",
                                                overflowY: "scroll",
                                                maxHeight: "500px",
                                                // top: `${articlePage ? "80%" : "80%"}`,
                                                // zIndex: "1000",
                                                // bottom: `${
                                                //     articlePage ? "-125px" : "-70px"
                                                // }`,
                                                // left: "50%",
                                                // transform: "translateX(-50%)",
                                                color: "black",
                                                boxShadow: "0 12px 22px  gray",
                                                borderRadius: "0 0 5px 5px",
                                                // width: `${
                                                //     articlePage ? "1038px" : "638px"
                                                // }`,
                                                width: "100%",
                                                textAlign: "left",
                                                marginInline: "auto",
                                                marginTop: "-14px",
                                                scrollbarWidth: "thin",
                                            }}
                                        >
                                            {searchResult.length > 0 &&
                                                searchResult.map((i, n) => (
                                                    <li
                                                        key={n}
                                                        style={{
                                                            padding:
                                                                "10px 50px",
                                                        }}
                                                    >
                                                        <Link
                                                            to={
                                                                i.questions
                                                                    ? helper.urlencoder(
                                                                          i.questions
                                                                      )
                                                                    : helper.urlencoder(
                                                                          i.main_topic
                                                                      )
                                                            }
                                                            style={{
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                        >
                                                            <p
                                                                className="p-0 m-0"
                                                                style={{
                                                                    fontWeight:
                                                                        "normal",
                                                                    color: "#484848",
                                                                    fontSize:
                                                                        "16px",
                                                                    lineHeight:
                                                                        "30px",
                                                                }}
                                                            >
                                                                {i.questions}
                                                            </p>
                                                            <h5
                                                                className="p-0 m-0"
                                                                style={{
                                                                    color: "#d23f99",
                                                                    fontSize:
                                                                        ".875rem",
                                                                    // fontWeight:
                                                                    //     "600",
                                                                    lineHeight:
                                                                        "1.5rem",
                                                                    fontFamily:
                                                                        "Open Sans, sans-seriff",
                                                                }}
                                                            >
                                                                {i.sub_topic
                                                                    .length >
                                                                0 ? (
                                                                    <>
                                                                        {
                                                                            i.main_topic
                                                                        }
                                                                        <ChevronRightIcon
                                                                            sx={{
                                                                                color: "#d23f99",
                                                                            }}
                                                                        />
                                                                        {
                                                                            i.sub_topic
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    i.main_topic
                                                                )}
                                                            </h5>
                                                        </Link>
                                                    </li>
                                                ))}
                                        </ul>
                                    ) : (
                                        <div
                                            style={{
                                                // position: "absolute",
                                                background: "white",
                                                padding: "20px 50px",
                                                zIndex: "1000",
                                                // bottom: `${articlePage ? "-40px" : "15px"}`,
                                                // left: "50%",
                                                // top: `${articlePage ? "85%" : "85%"}`,
                                                // transform: "translateX(-50%)",
                                                color: "black",
                                                boxShadow: "0 12px 22px  gray",
                                                borderRadius: "0 0 5px 5px",
                                                // width: `${
                                                //     articlePage ? "1038px" : "638px"
                                                // }`,
                                                width: "100%",
                                                textAlign: "left",
                                                marginTop: "-14px",
                                            }}
                                        >
                                            No Result Found..
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {articlePage && (
                <div
                    className="d-none d-lg-block"
                    style={{ minHeight: "70px", width: "100%" }}
                ></div>
            )}
        </>
    );
}

export default LandingPageHeader;
