import React, { Component, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Collapse, Modal, ModalHeader, ModalBody } from 'reactstrap';
// import DownIcon from 'mdi-react/ChevronDownIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import CloseIcon from 'mdi-react/CloseIcon';
import PropTypes from 'prop-types';


const ar = `${process.env.PUBLIC_URL}/img/flags/sa.svg`;
const en = `${process.env.PUBLIC_URL}/img/flags/us.svg`;
const es = `${process.env.PUBLIC_URL}/img/flags/es.svg`;
const hi = `${process.env.PUBLIC_URL}/img/flags/in.svg`;
const ja = `${process.env.PUBLIC_URL}/img/flags/jp.svg`;
const th = `${process.env.PUBLIC_URL}/img/flags/th.svg`;
const zh = `${process.env.PUBLIC_URL}/img/flags/cn.svg`;

const ArLng = () => (
    <span className="topbar__language-btn-title">
        <img src={ar} alt="ar" />
        <span style={{ fontWeight: '500', marginLeft: '5px' }}>Arabic</span>
        <span>( عربى )</span>
    </span>
);

const ArImg = () => (
    <span className="topbar__language-btn-title">
        <img src={ar} alt="ar" />
    </span>
);

const EnLng = () => (
    <span className="topbar__language-btn-title">
        <img src={en} alt="en" />
        <span style={{ fontWeight: '500', marginLeft: '5px' }} >English</span>
    </span>
);

const EnImg = () => (
    <span className="topbar__language-btn-title">
        <img src={en} alt="en" />
    </span>
);

const EsLng = () => (
    <span className="topbar__language-btn-title">
        <img src={es} alt="es" />
        <span style={{ fontWeight: '500', marginLeft: '5px' }}>Spanish</span>
        <span>( Española )</span>
    </span>
);

const EsImg = () => (
    <span className="topbar__language-btn-title">
        <img src={es} alt="es" />
    </span>
);

const HiLng = () => (
    <span className="topbar__language-btn-title">
        <img src={hi} alt="hi" />
        <span style={{ fontWeight: '500', marginLeft: '5px' }}>Hindi</span>
        <span>( हिन्दी )</span>
    </span>
);

const HiImg = () => (
    <span className="topbar__language-btn-title">
        <img src={hi} alt="hi" />
    </span>
);

const JaLng = () => (
    <span className="topbar__language-btn-title">
        <img src={ja} alt="ja" />
        <span style={{ fontWeight: '500', marginLeft: '5px' }}>Japanese</span>
        <span>( 日本 )</span>
    </span>
);

const JaImg = () => (
    <span className="topbar__language-btn-title">
        <img src={ja} alt="ja" />
    </span>
);

const ThLng = () => (
    <span className="topbar__language-btn-title">
        <img src={th} alt="th" />
        <span style={{ fontWeight: '500', marginLeft: '5px' }}>Thai</span>
        <span>( ไทย )</span>
    </span>
);

const ThImg = () => (
    <span className="topbar__language-btn-title">
        <img src={th} alt="th" />
    </span>
);

const ZhLng = () => (
    <span className="topbar__language-btn-title">
        <img src={zh} alt="zh" />
        <span style={{ fontWeight: '500', marginLeft: '5px' }}>Chinese</span>
        <span>( 中国人 )</span>
    </span>
);

const ZhImg = () => (
    <span className="topbar__language-btn-title">
        <img src={zh} alt="zh" />
    </span>
);


const TopbarLanguage = (props) => {

    const [collapse, setCollapse] = useState(false)
    const [mainButtonContent, setMainButtonContent] = useState(<EnImg />)
    let getlocalData = sessionStorage.getItem("languageCode");

    const toggle = () => setCollapse(!collapse)

    const changeLanguage = (lng) => {
        window.alert(lng)
        // sessionStorage.setItem("languageCode", lng)
        // const { i18n } = props;
        // i18n.changeLanguage(lng);
        // switch (lng) {
        //     case 'ar':
        //         setMainButtonContent(<ArImg />);
        //         break;
        //     case 'en':
        //         setMainButtonContent(<EnImg />);
        //         break;
        //     case 'es':
        //         setMainButtonContent(<EsImg />);
        //         break;
        //     case 'hi':
        //         setMainButtonContent(<HiImg />);
        //         break;
        //     case 'ja':
        //         setMainButtonContent(<JaImg />);
        //         break;
        //     case 'th':
        //         setMainButtonContent(<ThImg />);
        //         break;
        //     case 'zh':
        //         setMainButtonContent(<ZhImg />);
        //         break;
        //     default:
        //         setMainButtonContent(<EnImg />);
        //         break;
        // }
    };

    useEffect(() => {

        if (!getlocalData) {
            changeLanguage("en");
        } else {
            changeLanguage(getlocalData);
        }

    }, [getlocalData])

    return (
        <div className="topbar__collapse topbar__collapse--language mr-0" style={{ minWidth: '60px' }}>
            <button className="topbar__btn" type="button" onClick={toggle} style={{ borderLeft: '1px solid #eee', justifyContent: 'right' }}>
                {mainButtonContent}
                {/* <DownIcon className="topbar__icon mr-0 ml-0" /> */}
                <KeyboardArrowDownIcon sx={{
                    color: "#d23f99",
                }} className="topbar__icon mr-0 ml-0" />
            </button>


            {props.options == 'popup' && <Modal
                centered
                isOpen={collapse}
                toggle={toggle}
                className='mx-auto'
            >
                <ModalHeader>
                    <button
                        // lnr lnr-cross
                        className=" modal__close-btn"
                        style={{
                            top: "14px",
                            right: "17px",
                            fontSize: "18px",
                        }}
                        type="button"
                        onClick={toggle}
                    >
                        {/* <CloseIcon className="" /> */}
                    </button>
                    {/* checkbox-btn__label-uncheck */}
                    <div className="text-center">
                        <h5 style={{ fontWeight: "bold" }}>
                            Choose your language
                        </h5>
                    </div>
                </ModalHeader>
                <ModalBody style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: '10px' }}>
                    <button
                        className="topbar__language-btn"
                        style={{ border: '1px solid lightgray' }}
                        type="button"
                        onClick={() => { changeLanguage('en'); toggle() }}
                    >
                        <EnLng />
                    </button>
                    <button
                        className="topbar__language-btn"
                        style={{ border: '1px solid lightgray' }}
                        type="button"
                        onClick={() => { changeLanguage('ar'); toggle() }}
                    >
                        <ArLng />
                    </button>
                    <button
                        className="topbar__language-btn"
                        style={{ border: '1px solid lightgray' }}
                        type="button"
                        onClick={() => { changeLanguage('es'); toggle() }}
                    >
                        <EsLng />
                    </button>
                    <button
                        className="topbar__language-btn"
                        style={{ border: '1px solid lightgray' }}
                        type="button"
                        onClick={() => { changeLanguage('hi'); toggle() }}
                    >
                        <HiLng />
                    </button>
                    <button
                        className="topbar__language-btn"
                        style={{ border: '1px solid lightgray' }}
                        type="button"
                        onClick={() => { changeLanguage('ja'); toggle() }}
                    >
                        <JaLng />
                    </button>
                    <button
                        className="topbar__language-btn"
                        style={{ border: '1px solid lightgray' }}
                        type="button"
                        onClick={() => { changeLanguage('th'); toggle() }}
                    >
                        <ThLng />
                    </button>
                    <button
                        className="topbar__language-btn"
                        style={{ border: '1px solid lightgray' }}
                        type="button"
                        onClick={() => { changeLanguage('zh'); toggle() }}
                    >
                        <ZhLng />
                    </button>
                </ModalBody>

            </Modal>
            }
            {collapse && (
                <button
                    className="topbar__back"
                    type="button"
                    onClick={toggle}
                />
            )}

            {props.options == 'dropdown' &&
                <Collapse
                    isOpen={collapse}
                    className="topbar__collapse-content topbar__collapse-content--language"
                    style={{ maxWidth: '150px', width: 'max-content', zIndex: 101 }}
                >
                    <div
                    >
                        <button
                            className="topbar__language-btn"
                            type="button"
                            onClick={() => { changeLanguage('en'); toggle() }}
                        >
                            <EnLng />
                        </button>
                        <button
                            className="topbar__language-btn"
                            type="button"
                            onClick={() => { changeLanguage('ar'); toggle() }}
                        >
                            <ArLng />
                        </button>
                        <button
                            className="topbar__language-btn"
                            type="button"
                            onClick={() => { changeLanguage('es'); toggle() }}
                        >
                            <EsLng />
                        </button>
                        <button
                            className="topbar__language-btn"
                            type="button"
                            onClick={() => { changeLanguage('hi'); toggle() }}
                        >
                            <HiLng />
                        </button>
                        <button
                            className="topbar__language-btn"
                            type="button"
                            onClick={() => { changeLanguage('ja'); toggle() }}
                        >
                            <JaLng />
                        </button>
                        <button
                            className="topbar__language-btn"
                            type="button"
                            onClick={() => { changeLanguage('th'); toggle() }}
                        >
                            <ThLng />
                        </button>
                        <button
                            className="topbar__language-btn"
                            type="button"
                            onClick={() => { changeLanguage('zh'); toggle() }}
                        >
                            <ZhLng />
                        </button>
                    </div>

                </Collapse >
            }
        </div >
    );

}

TopbarLanguage.propTypes = {
    i18n: PropTypes.func.isRequired,
};

export default withTranslation('common')(TopbarLanguage);
