import React from "react";

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import LandingPageBody from "pages/landingPage/LandingPageBody.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function LandingPage() {
    // const [firstFocus, setFirstFocus] = React.useState(false);
    // const [lastFocus, setLastFocus] = React.useState(false);
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>
            <ExamplesNavbar />
            <div className="wrapper">
                <LandingPageHeader title />
                <LandingPageBody /> <DefaultFooter />
            </div>
        </>
    );
}

export default LandingPage;
