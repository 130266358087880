const helper = {
    urlencoder: function (data) {
        // let regex1 = /"/gi;
        // let regex2 = /'/gi;
        // let regex3 = /,/gi;
        return data
            .toLowerCase()
            .replace(/[^a-zA-Z0-9\s ]/g, "")
            .replace(/\s/g, "-");
        // .replaceAll(regex1, "")
        // .replaceAll(regex2, "")
        // .replaceAll(regex3, "");
    },
};

export default helper;
