import React, { useContext, useEffect, useState } from "react";
import { Context } from "Store";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "./style.scss";
import client from "../../utils/HTTPClient";
import helper from "helpers/helpers";
import api from "../../utils/apilist";
import { toast } from "react-toastify";

// import GettingStartedIcon from "../../assets/img/homepage-icons/get-started-icon.png";
// import MenuIcon from "../../assets/img/homepage-icons/menus-icon.png";

const LandingPageBody = () => {
    const [state] = useContext(Context);
    const [current, setCurrent] = useState("");
    const [helpData, sethelpData] = useState([]);

    let path = process.browser && window.location.pathname;
    useEffect(() => {
        process.browser && setCurrent(window.location.pathname);
    }, [path]);
    // console.log("global state==>", state);

    const fetchHelpData = () => {
        const helpData = {};
        client.post(api.fetchHelptopics, helpData, (error, response) => {
            if (!error) {
                if (!response.error) {
                    try {
                        if (!response.error) {
                            // console.log("helpData ==>", response);
                            // sethelpTopic(response.result.data);
                            sethelpData(response.result.data);
                        } else {
                            console.log(response.message);
                        }
                    } catch (error) {
                        toast.error("error occured");
                    }
                } else {
                    toast.error("internal error occured");
                }
            } else {
                toast.error(error);
            }
            // console.log(helpTopic);
        });
    };
    useEffect(() => {
        fetchHelpData();
    }, []);

    // let data = [
    //     {
    //         icon_image: <img src={GettingStartedIcon} alt="" />,
    //         para: (
    //             <>
    //                 <h5>Getting Started </h5>
    //                 <p>Your step-by-step guide to use iPayPro</p>
    //             </>
    //         ),
    //     },
    //     {
    //         icon_image: <img src={MenuIcon} alt="" />,
    //         para: (
    //             <>
    //                 <h5>Menus</h5>
    //                 <p>
    //                     Build, optimise and manage your online menu to provide a
    //                     great customer experience
    //                 </p>
    //             </>
    //         ),
    //     },
    //     {
    //         icon_image: (
    //             <img
    //                 src="https://help.bopple.com/hubfs/hc--icon__venue-management-2.png"
    //                 alt=""
    //             />
    //         ),
    //         para: (
    //             <>
    //                 <h5>Online Store</h5>
    //                 <p>
    //                     Customize the look and feel of your store and manage
    //                     your store profile information
    //                 </p>
    //             </>
    //         ),
    //     },
    //     {
    //         icon_image: (
    //             <img
    //                 src="https://help.bopple.com/hubfs/hc--icon__getting-started-1.png"
    //                 alt=""
    //             />
    //         ),
    //         para: (
    //             <>
    //                 <h5>Online Ordering Setup </h5>
    //                 <p>Your step-by-step guide to use iPayPro</p>
    //             </>
    //         ),
    //     },
    //     {
    //         icon_image: (
    //             <img
    //                 src="https://help.bopple.com/hubfs/hc--icon__venue-management-2.png"
    //                 alt=""
    //             />
    //         ),
    //         para: (
    //             <>
    //                 <h5>Manage Online Orders</h5>
    //                 <p>
    //                     Customize the look and feel of your store and manage
    //                     your store profile information
    //                 </p>
    //             </>
    //         ),
    //     },
    //     {
    //         icon_image: (
    //             <img
    //                 src="https://help.bopple.com/hubfs/hc--icon__menus.png"
    //                 alt=""
    //             />
    //         ),
    //         para: (
    //             <>
    //                 <h5>Customers & Feedback</h5>
    //                 <p>
    //                     Build, optimise and manage your online menu to provide a
    //                     great customer experience
    //                 </p>
    //             </>
    //         ),
    //     },
    // ];

    // let helpData = [];
    // helpData = state;

    // console.log("data ==>", helpData);
    // let arr = [...state];
    // let helpData = [...arr]
    // console.log('helpData', helpData)
    return (
        <div
            className="landing-page-body"
            style={{
                backgroundColor: `${current === "/" ? "#FFF" : "#f5f8fa"}`,
            }}
        >
            <section className="container-md" style={{ padding: "50px 0" }}>
                <Row>
                    <Col className="mx-auto" xs="10">
                        <h5 style={{ fontWeight: "bold" }} className="ml-4">
                            Help Topics
                        </h5>
                    </Col>
                    <hr style={{ width: "76%" }} className=" mx-auto " />
                </Row>

                <Row className="mt-3">
                    <Col
                        lg="11"
                        xl='10'
                        className="d-flex flex-wrap mx-auto help-cards"
                    >
                        {helpData.length > 0 &&
                            helpData.map((item, index) => (
                                <Col
                                    xs="11"
                                    md="10"
                                    lg="4"
                                    key={index}
                                    className=" mx-auto mx-lg-0 pb-0"
                                // py-md-4
                                >
                                    <Link
                                        to={`/${helper.urlencoder(
                                            item.main_topic
                                        )}`}
                                    >
                                        <Row className="justify-content-evenly h-75 m-0 p-0">
                                            <Row className="help-cards-box">
                                                <Col
                                                    xs="3"
                                                    sm="2"
                                                    lg="3"
                                                    className="mx-auto p-0 pe-1 px-xl-2"
                                                >
                                                    <img
                                                        src={client.url(
                                                            `/images/helpTopic/${item.icon_image}`
                                                        )}
                                                        alt="icons"
                                                    />
                                                    {/* {item.icon_image} */}
                                                </Col>
                                                <Col>
                                                    {item.main_topic ? (
                                                        <>
                                                            <h5>
                                                                {
                                                                    item.main_topic
                                                                }
                                                            </h5>
                                                            <p>
                                                                {
                                                                    item.description
                                                                }
                                                            </p>
                                                        </>
                                                    ) : (
                                                        item.para
                                                    )}
                                                    {/* {console.log(
                                                        "stateData==>",
                                                        state
                                                    )} */}
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Link>
                                    <hr />
                                </Col>
                            ))}
                    </Col>
                </Row>

                {/* <Row className="justify-content-evenly mt-5">
                    <Col xs="10" lg="3" className="">
                        <Row className="justify-content-between">
                            <Col xs="2">
                                <img
                                    src="https://help.bopple.com/hubfs/hc--icon__getting-started-1.png"
                                    alt=""
                                />
                            </Col>
                            <Col></Col>
                        </Row>
                    </Col>
                    <Col xs="10" lg="3" className="">
                        <Row className="justify-content-between">
                            <Col xs="2">
                                <img
                                    src="https://help.bopple.com/hubfs/hc--icon__venue-management-2.png"
                                    alt=""
                                />
                            </Col>
                            <Col>
                                <h5>Online Store</h5>
                                <p>
                                    Customize the look and feel of your store
                                    and manage your store profile information
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="10" lg="3" className="">
                        <Row className="justify-content-between">
                            <Col xs="2"></Col>
                            <Col>
                                <h5>Menus</h5>
                                <p>
                                    Build, optimise and manage your online menu
                                    to provide a great customer experience
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="justify-content-evenly mt-5">
                    <Col xs="10" lg="3" className="">
                        <Row className="justify-content-between">
                            <Col xs="2">
                                <img
                                    src="https://help.bopple.com/hubfs/hc--icon__getting-started-1.png"
                                    alt=""
                                />
                            </Col>
                            <Col>
                                <h5>Online Ordering Setup </h5>
                                <p>Your step-by-step guide to use iPayPro</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="10" lg="3" className="">
                        <Row className="justify-content-between">
                            <Col xs="2">
                                <img
                                    src="https://help.bopple.com/hubfs/hc--icon__venue-management-2.png"
                                    alt=""
                                />
                            </Col>
                            <Col>
                                <h5>Manage Online Orders</h5>
                                <p>
                                    Customize the look and feel of your store
                                    and manage your store profile information
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="10" lg="3" className="">
                        <Row className="justify-content-between">
                            <Col xs="2">
                                <img
                                    src="https://help.bopple.com/hubfs/hc--icon__menus.png"
                                    alt=""
                                />
                            </Col>
                            <Col>
                                <h5>Customers & Feedback</h5>
                                <p>
                                    Build, optimise and manage your online menu
                                    to provide a great customer experience
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row> */}
            </section>
        </div>
    );
};

export default LandingPageBody;
