import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import helper from "helpers/helpers";
import api from "../../utils/apilist";
import client from "../../utils/HTTPClient";
import { BeatLoader } from "react-spinners";

const getLocalItems = () => {
    let localEmail = localStorage.getItem("email");

    if (localEmail) {
        // console.log("localDATA =>", localEmail);
        return localEmail;
    } else {
        return "";
    }
};

// const feedBackChecker = () => {
//     let visitStatus = JSON.parse(localStorage.getItem("session"));

//     if (visitStatus.length > 0) {
//         console.log("visitStatus==>", visitStatus);
//         visitStatus.map((i) => {
//             if (i.articles == window.location.href) {
//                 console.log(i.articles == window.location.href);
//                 return true;
//             } else {
//                 return false;
//             }
//         });
//     }
// };

const ArticlesSubTopicBody = ({ pageContent, main_id, data }) => {
    const [isVisited, setIsVisited] = useState(false);
    // const [isVisited, SetisVisited] = useState(feedBackChecker());
    const [feedback, setFeedback] = useState("none");
    const [mailSubmit, setMailSubmit] = useState(false);
    const [Err, setErr] = useState(true);

    // emailing
    const [email, setEmail] = useState(getLocalItems());
    useEffect(() => {
        if (email == undefined) {
            localStorage.setItem("email", email);
        }
    }, [email]);

    const [loading, setLoading] = useState(false);

    const [result, setresult] = useState(null);

    const fetch_answer = async () => {
        client.post(api.fetch_answer, { id: pageContent._id }, async (error, response) => {
            if (!error) {
                if (typeof response.error !== "undefined") {
                    try {
                        if (!response.error) {
                            setresult(response.result.data);
                            setLoading(false)
                        }
                    } catch {

                    }
                }
            }
        })
    }

    useEffect(() => {
        fetch_answer()
    }, [])

    const feedBackChecker = () => {
        let visitStatus = JSON.parse(localStorage.getItem("session"));

        if (visitStatus != null) {
            if (visitStatus.length > 0) {
                // console.log(
                //     "visitStatus==>",
                //     visitStatus,
                //     visitStatus.indexOf(window.location.href) !== -1
                // );
                if (visitStatus.indexOf(window.location.href) !== -1) {
                    setIsVisited(true);
                }
                // visitStatus.map((i) => {
                //     console.log("items-->", i);
                //     if (i.articles == window.location.href) {
                //         setIsVisited(true);
                //     }
                // });
            }
        }
    };
    useEffect(() => {
        feedBackChecker();
    }, []);

    let feedbacks = [];
    feedbacks = JSON.parse(localStorage.getItem("session")) || [];
    // if(email != undefined)
    // console.log("email-->", email);
    const handleFeedback = (e) => {
        if (e == 1) {
            setFeedback("yes");
            if (email) {
                // console.log("email is");
                feedbacks.push(window.location.href);
                localStorage.setItem("session", JSON.stringify(feedbacks));

                let feedbackData = {
                    email: localStorage.getItem("email"),
                    article_name: window.location.href,
                    body: `Good job, one of the visitors has marked the below article useful.\n Keep up the good work!`,
                    // feedback == "yes"?
                    // : `Kindly respond to below email within 24hours to check how we can improve the article`,
                    subject: `User was satisfied with the article`,
                    // feedback == "yes"
                    //     ?
                    //         : `User not satisfied with the article`,
                };
                client.post(
                    api.saveFeedback,
                    feedbackData,
                    (error, response) => {
                        console.log("err & res", error, response);
                        if (!error) {
                            if (!response.error) {
                                console.log(
                                    "feedback before email submit ==>",
                                    feedbackData
                                );
                            }
                        }
                    }
                );
            }
        } else {
            setFeedback("no");
            // let feedbacks = [];
            // feedbacks = JSON.parse(localStorage.getItem("session")) || [];
            if (email) {
                feedbacks.push(window.location.href);
                localStorage.setItem("session", JSON.stringify(feedbacks));

                let feedbackData = {
                    email: localStorage.getItem("email"),
                    article_name: window.location.href,
                    body: `Kindly respond to below email within 24hours to check how we can improve the article`,
                    // feedback == "yes"
                    //     ? `Good job, one of the visitors has marked the below article useful.\n Keep up the good work!`
                    subject: `User not satisfied with the article`,
                    // feedback == "yes"
                    //     ? `User was satisfied with the article`
                };
                client.post(
                    api.saveFeedback,
                    feedbackData,
                    (error, response) => {
                        console.log("err & res", error, response);
                        if (!error) {
                            if (!response.error) {
                                console.log(
                                    "feedback before email submit ==>",
                                    feedbackData
                                );
                            }
                        }
                    }
                );
            }
        }
    };
    // console.log("visited==>", isVisited);

    // console.log("local==>", email);
    // console.log(email == true);

    const handleEmail = (e) => {
        if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)
        ) {
            // setEmail(e.target.value);
            localStorage.setItem("email", e.target.value);
            // setEmail
            // console.log("changes==>", e);
            setErr(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let feedbackData = {
            email: localStorage.getItem("email"),
            article_name: window.location.href,
            body:
                feedback == "yes"
                    ? `Good job, one of the visitors has marked the below article useful.\n Keep up the good work!`
                    : `Kindly respond to below email within 24hours to check how we can improve the article`,
            subject:
                feedback == "yes"
                    ? `User was satisfied with the article`
                    : `User not satisfied with the article`,
        };

        feedbacks.push(window.location.href);
        localStorage.setItem("session", JSON.stringify(feedbacks));

        client.post(api.saveFeedback, feedbackData, (error, response) => {
            console.log("err & res", error, response);
            if (!error) {
                setMailSubmit(true);
            }
        });
        // setMailSubmit(true);
        console.log("feedback after email submit ==>", feedbackData);
    };

    // related articles
    // let shuffled =  relatedArticles[Math.floor(Math.random()* relatedArticles.length)]
    let modData;
    data.filter((e) => e._id == main_id).map((item) => (modData = item));
    let relatedQuestions = [];
    // console.log("modData==>", modData);
    if (modData.questions.length > 0) {
        modData.questions.map((i) => {
            // console.log("questions==>", i.questions);
            relatedQuestions.push(i.questions);
        });
    }
    if (modData.sub_topic.length > 0) {
        modData.sub_topic.map((item) => {
            // relatedQuestions.push(i)
            // console.log("i==>", item);
            if (item.questions.length > 0) {
                item.questions.map((i) => {
                    relatedQuestions.push(i.questions);
                });
            }
        });
    }
    // console.log("questions==>", relatedQuestions);
    // excluding the current link
    const exclude = (arr, value) => {
        let index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    };
    let shuffled = exclude(relatedQuestions, pageContent.topic);
    // console.log("exclude==>", exclude(relatedQuestions, pageContent.topic));

    // let shuffled = relatedQuestions.sort(() => 0.5 - Math.random());
    // exclude(shuffled, pageContent.topic);
    // console.log("shuff==>", shuffled);
    if (!result) {
        return <div className="d-flex align-items-center justify-content-center" style={{ height: "50vh" }}><BeatLoader color="#d23f99" /></div>
    }

    return (
        <div>
            {/* <Link to="/">back home</Link> */}
            {pageContent ? (
                <section>
                    <div
                        className="content-body"
                        dangerouslySetInnerHTML={{ __html: result.answer }}
                    ></div>
                </section>
            ) : (
                <section>
                    <h1>No answers yet</h1>
                </section>
            )}
            {!isVisited && (
                <>
                    {feedback == "none" ? (
                        <section className="feedback-wrapper d-flex flex-column flex-lg-row align-items-lg-center">
                            <div className="feedback-question px-1">
                                <span className="feedback-text">
                                    Was this article helpful?
                                </span>
                            </div>
                            <div className="rating-btns">
                                <button
                                    className="px-2 mx-2"
                                    value="1"
                                    onClick={(e) => {
                                        handleFeedback(e.target.value);
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    className="px-2 mx-2"
                                    value="0"
                                    onClick={(e) => {
                                        handleFeedback(e.target.value);
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </section>
                    ) : feedback == "yes" ? (
                        <section>
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                }}
                            >
                                Thanks for your feedback. We're glad we could
                                help.
                            </p>
                        </section>
                    ) : (
                        <section>
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                }}
                            >
                                Thank you. Your feedback will be used to make
                                future improvements.
                            </p>
                        </section>
                    )}
                </>
            )}
            {(feedback == "yes" || feedback == "no") && !email && (
                <section>
                    {/* {!email == undefined && */}
                    <div>
                        {!mailSubmit ? (
                            <form>
                                <div className="form-group">
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        style={{ fontWeight: "600" }}
                                    >
                                        Help us give you a better customer
                                        experience and leave us your email.
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="name@email.com"
                                        style={{
                                            borderRadius: "3px",
                                            fontSize: "14px",
                                        }}
                                        onChange={handleEmail}
                                        required
                                    />
                                    {!Err && (
                                        <button
                                            className="submit-mail mt-3"
                                            type="submit"
                                            id="submit-btn"
                                            style={{
                                                width: "113px",
                                                border: "1px solid #d23f99",
                                                borderRadius: "3px",
                                                height: "32px",
                                                fontSize: "12px",
                                            }}
                                            onClick={handleSubmit}
                                        >
                                            Submit email
                                        </button>
                                    )}
                                </div>
                            </form>
                        ) : (
                            <p style={{ fontWeight: "600", fontSize: "14px" }}>
                                Your email’s been submitted.
                            </p>
                        )}
                    </div>
                    {/* } */}
                </section>
            )}
            <section>
                <div className="related-div">
                    <h3>Related articles</h3>
                    <ul>
                        {relatedQuestions.length > 0 &&
                            shuffled.length > 0 &&
                            shuffled.map((i, no) => {
                                {
                                    /* console.log(i); */
                                }
                                return (
                                    <li key={no}>
                                        <Link to={`${helper.urlencoder(i)}`}>
                                            {i}
                                        </Link>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </section>
        </div>
    );
};

export default ArticlesSubTopicBody;
