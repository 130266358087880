import React, { useState, useEffect, useRef } from "react";
import TranslateIcon from '@mui/icons-material/Translate';

const GoogleTranslate = ({ forMobile }) => {

    const resetGoogTrans = () => {
		try {
			let x = document.querySelectorAll('iframe.skiptranslate');
			for (var i = 0; i < x.length; i++) {
				let y = x[i].contentWindow.document.querySelectorAll('span.text')
				if (y.length) {
					for (var j = 0; j < y.length; j++) {
						if (y[j].textContent.includes("Turn off translation for this site")) {
							y[j].click();
						}
					}
				}
			}
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		const observer = new MutationObserver(() => {
			try {
				let match = document.cookie.match(new RegExp('(^| )' + "googtrans" + '=([^;]+)'));
				if (match && match[2] == "/en/en") {
					resetGoogTrans();
				}
			} catch (err) {
				console.log(err);
			}
		});

		observer.observe(document.documentElement, {
			childList: true,
			subtree: true,
		});

		return () => {
			observer.disconnect();
		};
	}, []);

    const [translateStyleObj, setTranslateStyleObj] = useState(forMobile ?
        { position: "absolute", right: "5%", top: "50px", display: "none", zIndex: "99999", backgroundColor: "#000", borderRadius: "10px", padding: "25px" } :
        { position: "absolute", right: "5%", top: "80px", display: "none", zIndex: "99999", backgroundColor: "#000", borderRadius: "10px", padding: "25px" }
    );
    const googleTranslateBox = useRef(null);

    function googleTranslateElementInit() {
        let removeElem = document.getElementById("google_translate_element");
        if (removeElem.childNodes.length === 0) {
            try {
                new window.google.translate.TranslateElement(
                    { pageLanguage: 'en' },
                    'google_translate_element'
                );
            } catch {

            }
        }
    }

    useEffect(() => {
        window.googleTranslateElementInit = googleTranslateElementInit;
        var addScript = document.createElement('script');
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        addScript.setAttribute('async', 'true');
        addScript.setAttribute('type', 'text/javascript');
        document.body.appendChild(addScript);
    }, [])

    // const useOutsideAlerter = (ref) => {
    //     useEffect(() => {
    //         const handleOutsideClick = (event) => {
    //             if (ref.current && !ref.current.contains(event.target)) {
    //                 setTranslateStyleObj({ ...translateStyleObj, display: "none" })
    //             }
    //         }
    //         document.addEventListener("click", handleOutsideClick);
    //         return () => document.removeEventListener("click", handleOutsideClick);
    //     }, [ref]);
    // }

    // useOutsideAlerter(googleTranslateBox);

    useEffect(() => {
        let handler = (e) => {
            if (googleTranslateBox.current && !googleTranslateBox.current.contains(e.target)) {
                setTranslateStyleObj({ ...translateStyleObj, display: "none" })
            }
        }
        document.addEventListener('mousedown', handler)
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    }, [])

    const handleTranslate = () => translateStyleObj.display === "none" ? setTranslateStyleObj({ ...translateStyleObj, display: "block" }) : setTranslateStyleObj({ ...translateStyleObj, display: "none" });

    return (
        <>
            {
                forMobile ?
                    <span className='nav-item p-2' style={{ borderRadius: "3px", background: 'white' }} ref={googleTranslateBox}>
                        <TranslateIcon onClick={handleTranslate} style={{
                            color: '#d23f99',
                            cursor: 'pointer',
                        }} />
                        <div style={translateStyleObj}>
                            <div id="google_translate_element"></div>
                        </div>
                    </span>
                    :
                    <div className='nav-item' style={{ padding: "10px 15px" }} ref={googleTranslateBox}>
                        <TranslateIcon onClick={handleTranslate} style={{
                            color: '#ffffff',
                            cursor: 'pointer',
                        }} />
                        <div style={translateStyleObj}>
                            <div id="google_translate_element"></div>
                        </div>
                    </div>
            }
        </>
    )
}

export default GoogleTranslate;