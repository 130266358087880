/*

=========================================================
* Now UI Kit React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// styles for this kit
import "assets/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
// import Index from "views/Index.js";
// import NucleoIcons from "views/NucleoIcons.js";
// import LoginPage from "views/examples/LoginPage.js";
// import ProfilePage from "views/examples/ProfilePage.js";
// import LandingPage from "views/examples/LandingPage.js";
// import HelpArticlesPage from "pages/helpArticlesPage/HelpArticlesPage.jsx";

// import Root from "Root";

import Store from "../src/Store";
import Root from "Root";

ReactDOM.render(
    <Store>
        <Helmet>
            <title>{window.location.hostname === "help.linky.biz" ? `Linky: Help & Support` : `iPayPro: Help & Support`}</title>
        </Helmet>
        <BrowserRouter>
            <ToastContainer autoClose={3000} />
            {/* <Switch>
                <Route path="/index" render={(props) => <Index {...props} />} />
                <Route
                    path="/nucleo-icons"
                    render={(props) => <NucleoIcons {...props} />}
                />
                <Route
                    exact
                    path="/"
                    render={(props) => <LandingPage {...props} />}
                />
                <Route
                    path="/profile-page"
                    render={(props) => <ProfilePage {...props} />}
                />
                <Route
                    path="/login-page"
                    render={(props) => <LoginPage {...props} />}
                />
                <Route
                    path="/articles"
                    render={(props) => <HelpArticlesPage {...props} />}
                />
                <Redirect from="*" to="/" />
            </Switch> */}
            <Root />
        </BrowserRouter>
    </Store>,
    document.getElementById("root")
);
