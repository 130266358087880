import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import linky_logo from "../../assets/img/linky_logo2.png";
// reactstrap components
import {
    Collapse,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
    // UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
} from "reactstrap";

import SearchBarComp from "components/SearchBarComponent/SearchBarComp";

import "../../assets/css/style.scss";
import GoogleTranslate from "./GoogleTranslate";

function ArticleNavbar({ articlePage }) {
    // const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    // React.useEffect(() => {
    //     const updateNavbarColor = () => {
    //         if (
    //             document.documentElement.scrollTop > 399 ||
    //             document.body.scrollTop > 399
    //         ) {
    //             setNavbarColor("");
    //         } else if (
    //             document.documentElement.scrollTop < 400 ||
    //             document.body.scrollTop < 400
    //         ) {
    //             setNavbarColor("navbar-transparent");
    //         }
    //     };
    //     window.addEventListener("scroll", updateNavbarColor);
    //     return function cleanup() {
    //         window.removeEventListener("scroll", updateNavbarColor);
    //     };
    // });

    const articleNav = {
        background: "none",
        position: "fixed",
        width: "100%",
        zIndex: "1000",
    };

    const normalNav = {
        background: "transparent",
        position: "absolute",
        width: "100%",
        zIndex: "1000",
    };

    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar
                // className={"fixed-top " + navbarColor}
                className="mb-0  no-bs fixed-top "
                style={articlePage ? articleNav : normalNav}
                // style={{
                //     // background: "transparent",
                //     // position: "absolute",
                //     width: "100%",
                //     zIndex: "1000",
                //     // background: "#4E5160"
                // }}
                // color="info"
                expand="lg"
            >
                <div className="container ">
                {/* px-lg-0 px-xl-5 */}
                    <div
                        className={`navbar-translate align-items-center col-12 col-lg-3 col-xl-3 p-0 ${
                            articlePage
                                ? "justify-content-end justify-content-lg-start d-none d-lg-flex"
                                : "justify-content-between justify-content-lg-start d-flex"
                        }`}
                    >
                        <NavbarBrand
                            href="https://linky.biz/"
                            target="_blank"
                            id="navbar-brand"
                            className={`col-2 col-lg-5 col-xl-5 text-right m-0 p-0  ps-2
                             ${
                                articlePage ? "d-none d-lg-block" : ""
                            }`}
                            // pt-2
                        >
                            {/* Now Ui Kit */}
                            <img
                                className="navbar-brand-logo"
                                src={linky_logo}
                                alt="logo"
                                // style={{ width: "60%" }}
                            />
                        </NavbarBrand>
                        <NavLink
                            to={"/"}
                            tag={Link}
                            className="d-none d-lg-block "
                        >
                            Help Center
                        </NavLink>
                        <button
                            className="navbar-toggler navbar-toggler p-0"
                            onClick={() => {
                                document.documentElement.classList.toggle(
                                    "nav-open"
                                );
                                setCollapseOpen(!collapseOpen);
                            }}
                            aria-expanded={collapseOpen}
                            type="button"
                        >
                            <span
                                className={`navbar-toggler-bar top-bar ${
                                    articlePage && "black-bars"
                                }`}
                            ></span>
                            <span
                                className={`navbar-toggler-bar middle-bar ${
                                    articlePage && "black-bars"
                                }`}
                            ></span>
                            <span
                                className={`navbar-toggler-bar bottom-bar ${
                                    articlePage && "black-bars"
                                }`}
                            ></span>
                        </button>
                    </div>
                    {/* searchbar */}
                    <div className="col-4 col-xl-5 d-none d-lg-block ">
                    <SearchBarComp />
                    </div>

                    <Collapse
                        className="col-xl-4 justify-content-end justify-content-xl-start navwth100"
                        isOpen={collapseOpen}
                        navbar
                    >
                        <Nav
                            navbar
                            // style={{width:'340px'}}
                        >
                            <NavItem>
                                <NavLink
                                    href="https://linky.biz"
                                    //  to="/index" tag={Link}
                                >
                                    Go to linky.biz{" "}
                                    <i
                                        style={{ paddingLeft: "2px" }}
                                        className="fas fa-external-link-alt"
                                    ></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="https://linky.biz/contact-us">
                                    Contact us
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <GoogleTranslate />
                            </NavItem>
                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
        </>
    );
}

export default ArticleNavbar;
