import React, { useState, useEffect, useRef } from "react";
import { List, ListItemButton, ListItemText, Collapse } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CollapseData from "./CollapseData";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import helper from "../../helpers/helpers";

const SideBarList = ({ list }) => {
    // const [open, setOpen] = useState(false);
    const [temp, setTemp] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const [showSubContent, setShowSubContent] = useState(false);
    // console.log(showSubContent, "showSubContent")
    const expandState = [];
    const [expand, setExpand] = useState(expandState);
    const [selectedData, setSelectedData] = useState(null);

    const toggleSubData = () => {
        setShowSubContent(!showSubContent);
    };

    useEffect(() => {
        list.map((i, no) => {
            i.active = false;
            expandState.push({
                main_topic: i.main_topic,
                sub_topic: i.sub_topic,
                active: i.active,
            });
        });
    }, []);
    const handleClick = (item, index) => {
        // setOpen(!open);

        list.map((data, ind) => {
            if (item._id == data._id) {
                data.active = !data.active;
            }
        });
        let path = helper.urlencoder(item.main_topic);
        if (location.pathname !== path) {
            history.push(path);
        }
    };
    const subClick = (item, i) => {
        if (location.pathname !== `${helper.urlencoder(item.main_topic)}`) {
            // navigateToSec(i);
            history.push(`/${helper.urlencoder(item.main_topic)}`);
        }
        setTimeout(() => {
            navigateToSec(i);
        }, 500);
    };
    const navigateToSec = (i) => {
        window.location.href = `#${helper.urlencoder(i.title)}`;
    };
    useEffect(() => {
        return () => {
            if (history.action === "POP") {
                history.replace(history.location.pathname, "/");
            }
        };
    }, [history]);
    // console.info("sidebar list ==>", list);

    // console.log("expandState", expand);
    const toggleExpand = (item) => {
        setSelectedData(item);
        list.map((data) => {
            if (item._id == data._id) {
                // data.active = !data.active;
                toggleSubData();
                // console.log("clicked", data.active);
            }
        });
    };

    // ----stickyness functions----

    const controls = useRef();
    const points = useRef();
    const [fixedSideBar, setFixedSideBar] = useState(false);
    useEffect(() => {
        const initialTop = controls.current.getBoundingClientRect().top;
        console.log({ initialTop });
        const handleScroll = () => {
            setFixedSideBar(window.scrollY > initialTop - 90);
            // console.log(window.scrollY);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <div
            // className={`side-list-items-styles 
            // ${fixedSideBar && `custom-fixed-sideBar`}
            // `}
            className="side-list-items-styles"
            ref={controls}
            style={{
                // maxHeight: "450px",
                maxHeight: "calc(100vh - 180px)",
                overflowY: "auto",
                scrollbarWidth: "thin",
            }}
        >
            <List>
                {list.length > 0 &&
                    list.map((item, index) => (
                        <div key={index}>
                            <ListItemButton
                                className="p-0 list-item-hover"
                                // onClick={() => handleClick(item, index)}
                            >
                                <ListItemText
                                    onClick={() => handleClick(item, index)}
                                    className={"list-item-text p-2 px-3 m-0"}
                                    primary={item.main_topic}
                                    disableTypography
                                />
                                {item.sub_topic.length > 0 &&
                                    (showSubContent &&
                                    item._id === selectedData._id ? (
                                        <ExpandMore
                                            sx={{
                                                color: "#d23f99",
                                                zIndex: "10",
                                            }}
                                            onClick={() =>
                                                toggleExpand(item, index)
                                            }
                                        />
                                    ) : (
                                        <ChevronRightIcon
                                            sx={{
                                                color: "#d23f99",
                                                zIndex: "10",
                                            }}
                                            onClick={() =>
                                                toggleExpand(item, index)
                                            }
                                        />
                                    ))}
                            </ListItemButton>
                            {item.sub_topic.length > 0 && (
                                <Collapse
                                    in={
                                        showSubContent &&
                                        item._id === selectedData._id
                                    }
                                    timeout="auto"
                                    unmountOnExit
                                    className="sub-topics"
                                >
                                    <List component="div" disablePadding>
                                        {item.sub_topic.map((i, no) => (
                                            <ListItemButton
                                                key={no}
                                                sx={{
                                                    pl: 4,
                                                }}
                                                onClick={(e) =>
                                                    subClick(item, i)
                                                }
                                                //  {
                                                //     if (
                                                //         location.pathname ===
                                                //         `/${helper.urlencoder(
                                                //             item.main_topic
                                                //         )}
                                                //         `
                                                //     ) {
                                                //         window.location.href = `#${helper.urlencoder(
                                                //             i.title
                                                //         )}`;
                                                //     } else {
                                                //         history.push(
                                                //             `/${helper.urlencoder(
                                                //                 item.main_topic
                                                //             )}
                                                //         `
                                                //         );
                                                //         window.location.href = `#${helper.urlencoder(
                                                //             i.title
                                                //         )}`;
                                                //     }
                                                //     ---
                                                //     history.push(
                                                //         `/${helper.urlencoder(
                                                //             item.main_topic
                                                //         )}
                                                //         `
                                                //     #${helper.urlencoder(
                                                //         i.title
                                                //     )}
                                                //     );
                                                //     window.location.href = `#${helper.urlencoder(
                                                //         i.title
                                                //     )}`;
                                                // }}
                                            >
                                                {/* <a> */}
                                                <ListItemText
                                                    disableTypography
                                                    className="collapse-list-item m-0"
                                                    primary={i.title}
                                                />
                                                {/* </a> */}
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            )}
                        </div>
                    ))}
            </List>
        </div>
    );
};

export default SideBarList;
