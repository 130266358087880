/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Nav, NavLink, NavItem } from "reactstrap";

import linky_logo from "../../assets/img/linky_logo1.png";
// import ipaypos from "../../assets/img/IPAYPOS.png";
// core components
import LanguageTranslate from "components/TranslateButton/LanguageTranslate";

function DefaultFooter({ white }) {
    return (
        <div className="footer-comp">
            <footer
                className={`footer d-none d-lg-block ${white ? "" : "footer-default"
                    }`}
            >
                <Container>
                    <Row className="align-items-center">
                        <Col
                            lg="5"
                            xl="4"
                            xs="12"
                            className="d-lg-flex align-items-center "
                        >
                            <div className="footer-logo col-4 px-0 px-lg-1">
                                <a
                                    href="https://linky.biz/"
                                    target={"_blank"}
                                >
                                    <img src={linky_logo} width="100%" />
                                </a>
                            </div>
                            <div className="footer-title col mt-3 mt-lg-0 px-0">
                                simple.fast.secure
                            </div>
                        </Col>
                        <Col
                            lg="2"
                            xl="3"
                            xs="10"
                            className="mt-3 mt-lg-0 d-flex align-items-center"
                        >
                            <div className="social-links ">
                                <a
                                    // href="https://www.instagram.com/ipaypro_/"
                                    href="https://www.instagram.com/linky.biz/"
                                    target={"_blank"}
                                >
                                    <img
                                        src="https://static.hsstatic.net/TemplateAssets/static-1.17/img/hs_default_template_images/modules/Follow+Me+-+Email/instagram_circle_black.png"
                                        alt=""
                                        width={"25px"}
                                    />
                                </a>
                                <a
                                    // href="https://www.facebook.com/ipayprovenues"
                                    // href="https://www.facebook.com/ipayproau"
                                    href="https://www.facebook.com/linky.bizz"
                                    target={"_blank"}
                                >
                                    <img
                                        className="ms-4"
                                        src="https://static.hsstatic.net/TemplateAssets/static-1.17/img/hs_default_template_images/modules/Follow+Me+-+Email/facebook_circle_black.png"
                                        alt=""
                                        width={"25px"}
                                    />
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/ipaypos/"
                                    target={"_blank"}
                                >
                                    <img
                                        className="ms-4"
                                        src="https://static.hsstatic.net/TemplateAssets/static-1.17/img/hs_default_template_images/modules/Follow+Me+-+Email/linkedin_circle_black.png"
                                        alt=""
                                        width={"25px"}
                                    />
                                </a>
                            </div>
                        </Col>
                        <Col lg="5" xs="10" className="mt-3 mt-lg-0">
                            <Nav>
                                <NavItem>
                                    <NavLink
                                        href="https://linky.biz"
                                    //  to="/index" tag={Link}
                                    >
                                        Go to linky.biz{" "}
                                        <i
                                            style={{ paddingLeft: "2px" }}
                                            className="fas fa-external-link-alt"
                                        ></i>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="https://linky.biz/contact-us">
                                        Contact us
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </div>
    );
}

export default DefaultFooter;
